import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cfc73ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card bg-gray-300" }
const _hoisted_2 = { class: "score" }
const _hoisted_3 = { class: "stack" }
const _hoisted_4 = { class: "calculator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_backspace_icon = _resolveComponent("backspace-icon")!
  const _component_plus_icon = _resolveComponent("plus-icon")!
  const _component_minus_icon = _resolveComponent("minus-icon")!
  const _component_check_icon = _resolveComponent("check-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["status", _ctx.statusFontSize])
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.score), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formattedEquation), 1),
      _withDirectives(_createElementVNode("span", { class: "total" }, "= " + _toDisplayString(_ctx.total), 513), [
        [_vShow, _ctx.formattedEquation]
      ])
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "cell c-1",
        id: "btn1",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.press(1)), ["stop"]))
      }, "1"),
      _createElementVNode("button", {
        class: "cell c-2",
        id: "btn2",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.press(2)), ["stop"]))
      }, "2"),
      _createElementVNode("button", {
        class: "cell c-3",
        id: "btn3",
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.press(3)), ["stop"]))
      }, "3"),
      _createElementVNode("button", {
        class: "cell b-delete",
        id: "btnBackspace",
        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.backspace()), ["stop"]))
      }, [
        _createVNode(_component_backspace_icon)
      ]),
      _createElementVNode("button", {
        class: "cell c-1",
        id: "btn4",
        onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.press(4)), ["stop"]))
      }, "4"),
      _createElementVNode("button", {
        class: "cell c-2",
        id: "btn5",
        onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.press(5)), ["stop"]))
      }, "5"),
      _createElementVNode("button", {
        class: "cell c-3",
        id: "btn6",
        onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.press(6)), ["stop"]))
      }, "6"),
      _createElementVNode("button", {
        class: "cell c-1",
        id: "btn7",
        onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.press(7)), ["stop"]))
      }, "7"),
      _createElementVNode("button", {
        class: "cell c-2",
        id: "btn8",
        onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.press(8)), ["stop"]))
      }, "8"),
      _createElementVNode("button", {
        class: "cell c-3",
        id: "btn9",
        onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.press(9)), ["stop"]))
      }, "9"),
      _createElementVNode("button", {
        class: "cell c-1",
        id: "btn0",
        onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.press(0)), ["stop"]))
      }, "0"),
      _createElementVNode("button", {
        class: _normalizeClass(["cell b-plus", { active: _ctx.lastPress === '+' }]),
        id: "btnPlus",
        onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.press('+')), ["stop"]))
      }, [
        _createVNode(_component_plus_icon)
      ], 2),
      _createElementVNode("button", {
        class: _normalizeClass(["cell b-minus", { active: _ctx.lastPress === '-' }]),
        id: "btnMinus",
        onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (_ctx.press('-')), ["stop"]))
      }, [
        _createVNode(_component_minus_icon)
      ], 2),
      _createElementVNode("button", {
        class: "cell b-done",
        id: "btnDone",
        onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (_ctx.done()), ["stop"]))
      }, [
        _createVNode(_component_check_icon)
      ])
    ])
  ]))
}