
import { BeforeInstallPromptEvent } from 'event';
import { CloseIcon, SaveIcon } from '@/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      promptEvent: null as BeforeInstallPromptEvent | null,
    }
  },

  components: { CloseIcon, SaveIcon },

  mounted() {
    window.addEventListener('beforeinstallprompt', (e: Event) => {
      e.preventDefault();
      this.promptEvent = e as BeforeInstallPromptEvent;
    });

    window.addEventListener('appinstalled', () => this.installed());
  },

  methods: {
    installed() {
      return;
    },

    install() {
      if (!this.promptEvent) {
        return;
      }

      const e: BeforeInstallPromptEvent = this.promptEvent;
      e.prompt();
      e.userChoice.then((result) => {
        this.promptEvent = null;
        if (result === 'accepted') {
          this.$router.push({ name: 'installed' });
        }
      });
    }
  }
})
