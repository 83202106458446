
import { EditIcon } from '@/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['change'],

  components: { EditIcon },

  props: {
    text: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      editing: false,
      editText: '',
    }
  },

  methods: {
    save() {
      this.editing = false;
      this.$emit('change', this.editText);
      return this.editText;
    },

    edit() {
      this.editing = true;
      this.editText = this.text;
      const input = this.$refs.input as HTMLInputElement;
      this.$nextTick(() => input.focus());
    },

    cancel() {
      this.editing = false;
    }
  }
});
