import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Transition as _Transition, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "player-card" }
const _hoisted_2 = {
  class: "flex-shrink text-2xl",
  "data-cy": "player-name"
}
const _hoisted_3 = {
  class: "flex-grow text-right text-4xl font-mono",
  "data-cy": "player-score"
}
const _hoisted_4 = { class: "m-1 flex" }
const _hoisted_5 = { class: "m-1 flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delete_icon = _resolveComponent("delete-icon")!
  const _component_down_icon = _resolveComponent("down-icon")!
  const _component_up_icon = _resolveComponent("up-icon")!
  const _component_swipe_out = _resolveComponent("swipe-out")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createVNode(_Transition, { name: "remove" }, {
      default: _withCtx(() => [
        _createVNode(_component_swipe_out, {
          class: _normalizeClass(["card animated", { selected: _ctx.selected, left: _ctx.revealed === 'left', right: _ctx.revealed === 'right', [_ctx.background]: true }]),
          ref: "swipeout",
          onRevealRight: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$refs.swipeout.revealRight())),
          onRevealLeft: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$refs.swipeout.revealLeft())),
          onRightRevealed: _cache[6] || (_cache[6] = ($event: any) => (_ctx.revealed = 'right')),
          onLeftRevealed: _cache[7] || (_cache[7] = ($event: any) => (_ctx.revealed = 'left')),
          onClosed: _cache[8] || (_cache[8] = ($event: any) => (_ctx.revealed = ''))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "flex items-center cursor-pointer",
              "data-cy": "player-card",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', _ctx.player)))
            }, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.player.name), 1),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.player.score), 1)
            ])
          ]),
          left: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "btn alert square",
                title: "remove",
                "data-cy": "remove-player",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('remove', _ctx.player)))
              }, [
                _createVNode(_component_delete_icon)
              ])
            ])
          ]),
          right: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                class: "btn warn square",
                "data-cy": "minus-one",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('add', { player: _ctx.player, score: -1 })))
              }, [
                _createVNode(_component_down_icon)
              ]),
              _createElementVNode("button", {
                class: "btn secondary square",
                "data-cy": "plus-one",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('add', { player: _ctx.player, score: 1 })))
              }, [
                _createVNode(_component_up_icon)
              ])
            ])
          ]),
          _: 1
        }, 8, ["class"])
      ], undefined, true),
      _: 1
    })
  ]))
}