import { key } from '@/util/random';
import { Round } from './Round';

export {
  Player,
  newPlayer,
};

interface Player {
  id: string;
  name: string;
  score: number;
  rounds: Round[];
}

function newPlayer(overrides = {}): Player {
  return {
    id: key(),
    name: '',
    score: 0,
    rounds: [],
    ...overrides,
  };
}
