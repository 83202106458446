import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-shrink mr-2" }
const _hoisted_2 = { class: "flex-shrink text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_icon = _resolveComponent("edit-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex",
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.edit && _ctx.edit(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_edit_icon)
    ]),
    _withDirectives(_createElementVNode("span", { class: "flex-shrink" }, _toDisplayString(_ctx.text), 513), [
      [_vShow, !_ctx.editing]
    ]),
    _withDirectives(_createElementVNode("span", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["enter"])),
        ref: "input",
        class: "bg-black text-white font-mono border-0 text-3xl w-3/4",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editText) = $event))
      }, null, 544), [
        [_vModelText, _ctx.editText]
      ])
    ], 512), [
      [_vShow, _ctx.editing]
    ])
  ]))
}