
import { ago } from '@/util/ago';
import { SwipeOut } from 'vue-swipe-actions';
import { Game, lastPlayed, Player } from '@/store';
import { ArchiveIcon, CancelIcon, DeleteIcon, UnarchiveIcon } from '@/icons';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { ArchiveIcon, SwipeOut, DeleteIcon, CancelIcon, UnarchiveIcon },

  emits: ['select', 'remove', 'archive'],

  props: {
    game: {
      type: Object as PropType<Game>,
      required: true,
    },
  },

  data() {
    return {
      revealed: '',
    };
  },

  computed: {
    started() {
      return ago(this.game ? this.game.date : 0);
    },

    lastPlayed() {
      return ago(lastPlayed(this.game));
    },
  },

  methods: {
    winner(player: Player): boolean {
      if (!this.game) {
        return false;
      }

      return this.game.players.every((current: Player) => {
        return player.id === current.id || player.score > current.score;
      });
    },
  }
});
