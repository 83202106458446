
import { SwipeOut } from 'vue-swipe-actions';
import { DeleteIcon, UpIcon, DownIcon } from '@/icons';
import { Player } from '@/store';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { SwipeOut, DeleteIcon, UpIcon, DownIcon },
  
  props: {
    player: {
      type: Object as PropType<Player>,
      required: true,
    },
    selected: {
      type: Boolean,
      readonly: true,
      default: false,
    },
    background: {
      type: String,
      default: 'bg-neutral-50',
    }
  },

  emits: ['select', 'remove', 'add'],

  data() {
    return {
      revealed: '',
    };
  },
})
