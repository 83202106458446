
import { Game, Player } from '@/store';
import Calculator from '@/components/Calculator.vue';
import Editable from '@/components/Editable.vue';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
  components: {
    Editable,
    Calculator,
  },

  props: {
    gameId: {
      type: String,
      required: true,
      readonly: true,
    },
    playerId: {
      type: String,
      required: true,
      readonly: true,
    },
  },

  computed: {
    games(): Game[] {
      return this.$store.state.games ?? [];
    },

    game(): Game | undefined {
      return this.games.find((game) => game.id === this.gameId);
    },

    player(): Player | undefined {
      return this.game?.players.find((player) => player.id === this.playerId);
    },
  },

  methods: {
    ...mapActions(['updatePlayerName', 'addRound']),

    add(score: number): void {
      if (!this.game || !this.player) {
        return;
      }
      this.addRound({ gameId: this.game.id, playerId: this.player.id, score });
      this.$router.push({ name: 'game', params: { id: this.game.id } });
    }
  },

  mounted() {
    if (!this.player) {
      this.$router.push({ name: 'home' });
    }
  }
})
