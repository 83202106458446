import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "active-games" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "archived-games" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_icon = _resolveComponent("add-icon")!
  const _component_GameCard = _resolveComponent("GameCard")!
  const _component_expand_more_icon = _resolveComponent("expand-more-icon")!
  const _component_expand_less_icon = _resolveComponent("expand-less-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startGame())),
      "data-cy": "new-game",
      class: "btn full primary"
    }, [
      _createTextVNode("New Game "),
      _createVNode(_component_add_icon)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeGames, (game) => {
        return (_openBlock(), _createBlock(_component_GameCard, {
          key: game.id,
          game: game,
          onSelect: (game) => _ctx.selectGame(game),
          onRemove: (game) => _ctx.removeGame(game.id),
          onArchive: (game) => _ctx.archiveGame(game.id)
        }, null, 8, ["game", "onSelect", "onRemove", "onArchive"]))
      }), 128))
    ]),
    (_ctx.archivedGames.length > 0)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showArchive = !_ctx.showArchive)),
          "data-cy": "show-archive",
          class: "btn full secondary"
        }, [
          (!_ctx.showArchive)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createTextVNode("Show Archive "),
                _createVNode(_component_expand_more_icon)
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createTextVNode("Hide Archive "),
                _createVNode(_component_expand_less_icon)
              ]))
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.archivedGames, (game) => {
        return (_openBlock(), _createBlock(_component_GameCard, {
          key: game.id,
          game: game,
          onSelect: (game) => _ctx.selectGame(game),
          onRemove: (game) => _ctx.removeGame(game.id),
          onArchive: (game) => _ctx.unarchiveGame(game.id)
        }, null, 8, ["game", "onSelect", "onRemove", "onArchive"]))
      }), 128))
    ], 512), [
      [_vShow, _ctx.showArchive]
    ])
  ]))
}