
import { PlusIcon, MinusIcon, CheckIcon, BackspaceIcon } from '@/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    PlusIcon,
    MinusIcon,
    CheckIcon,
    BackspaceIcon,
  },

  emits: ['add'],

  props: {
    score: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      stack: [] as number[],
      value: '',
      lastPress: '+',
      operator: '+',
    };
  },

  computed: {
    workingValue(): number {
      const num = parseInt(this.value, 10);
      if (isNaN(num)) {
        return 0;
      }
      return this.operator === '-' ? 0 - num : num;
    },

    total(): number {
      return this.score + this.fullStack.reduce((total, value) => total + value, 0);
    },

    fullStack(): number[] {
      const full = [...this.stack];
      full.push(this.workingValue);
      return full.filter((value) => value !== 0);
    },

    formattedEquation(): string {
      const full = [];

      this.fullStack.forEach((value) => {
        full.push(value < 0 ? '-' : '+');
        full.push(Math.abs(value).toString());
      });

      if (this.operator === this.lastPress) {
        full.push(this.operator);
      }
      return full.join('');
    },

    statusFontSize(): string {
      const length = this.score.toString().length +
        this.formattedEquation.length +
        this.total.toString().length;
      if (length < 15) {
        return 'text-3xl';
      } else if (length < 20) {
        return 'text-2xl';
      } else if (length < 25) {
        return 'text-xl';
      } else if (length < 30) {
        return 'text-l';
      } else if (length < 35) {
        return 'text-md';
      } else {
        return 'text-sm';
      }
    }
  },

  methods: {
    isOperator(key: string): boolean {
      return key === '+' || key === '-';
    },

    done() {
      const total = this.total - this.score;
      this.$emit('add', total);

      return total;
    },

    press(pressed: string | number) {
      const key = pressed.toString();
      if (this.isOperator(key)) {
        if (this.value.length > 0) {
          this.stack.push(this.workingValue);
        }
        this.operator = key;
        this.lastPress = key;
        this.value = '';
        return;
      }
      this.value += key;
      this.lastPress = key;
    },

    backspace() {
      if (this.value.length > 0) {
        // A current value exists. Trim off the last character.
        this.value = this.value.substring(0, this.value.length - 1);
      } else if (this.stack.length === 0) {
        // No value, No stack. Just reset things.
        this.value = '';
      } else {
        // Trim off the last character of the previous stack.
        const last = this.stack.pop() || 0;
        const tail = Math.abs(last).toString();
        this.operator = last > 0 ? '+' : '-';
        this.value = tail.substring(0, tail.length - 1);
      }

      // Check to see if we need to display the operator
      if (this.value.length > 0) {
        this.lastPress = this.value.charAt(this.value.length - 1);
      } else {
        this.lastPress = this.operator;
      }
    }
  }
});
