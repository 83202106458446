
import { CloseIcon } from '@/icons';
import { ago } from '@/util/ago';
import { Game, Round } from '@/store';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { CloseIcon },

  props: {
    gameId: {
      type: String,
      required: true,
    },
  },

  computed: {
    games(): Game[] {
      return this.$store.state.games ?? [];
    },

    game(): Game | null | undefined {
      return this.gameId ? this.games.find((game) => game.id === this.gameId) : null;
    },
  },

  methods: {
    fromNow(time: number): string {
      return ago(time);
    },

    sortedRounds(rounds: Round[]): Round[] {
      const sorted = [...rounds];
      sorted.sort((a, b) => a.time - b.time);
      return sorted;
    }
  }
})
