import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import { Game, newGame } from "./Game";
import { Player, newPlayer } from "./Player";
import { GameState } from "./GameState";

export * from "./Game";
export * from "./Player";
export * from "./Round";
export * from "./GameState";

const vuexLocal = new VuexPersistence<GameState>({
  storage: window.localStorage,
});

export const store = createStore<GameState>({
  state: {
    games: [],
  },
  mutations: {
    addGame(state: GameState, game: Game) {
      state.games.push(game);
    },
    removeGame(state: GameState, gameId: string) {
      withGame(state, gameId, (gameIndex) => {
        state.games.splice(gameIndex, 1);
      });
    },
    archiveGame(state: GameState, gameId: string) {
      withGame(state, gameId, (gameIndex) => {
        state.games[gameIndex].archived = true;
      });
    },
    unarchiveGame(state: GameState, gameId: string) {
      withGame(state, gameId, (gameIndex) => {
        state.games[gameIndex].archived = false;
      });
    },
    updateGameName(state: GameState, { gameId, name }) {
      withGame(state, gameId, (gameIndex) => {
        state.games[gameIndex].name = name;
      });
    },
    addRound(state: GameState, { gameId, playerId, score }) {
      withPlayer(state, gameId, playerId, (gameIndex, playerIndex) => {
        const player = state.games[gameIndex].players[playerIndex];
        if (!player.rounds) {
          player.rounds = [];
        }
        player.rounds.push({ time: Date.now(), score });
        player.score += score;
      });
    },
    addPlayer(state: GameState, gameId: string) {
      withGame(state, gameId, (gameIndex) => {
        const game = state.games[gameIndex];
        const player = newPlayer({
          name: playerName(state.games, game.players.length),
        });
        game.players.push(player);
      });
    },
    updatePlayerName(state: GameState, { gameId, playerId, name }) {
      withPlayer(state, gameId, playerId, (gameIndex, playerIndex) => {
        state.games[gameIndex].players[playerIndex].name = name;
      });
    },
    removePlayer(state: GameState, { gameId, playerId }) {
      withPlayer(state, gameId, playerId, (gameIndex, playerIndex) => {
        state.games[gameIndex].players.splice(playerIndex, 1);
      });
    },
  },
  actions: {
    createGame({ commit, state }, playerCount = 2) {
      const game = newGame(playerCount);
      game.name = `Game ${state.games.length + 1}`;
      game.players.forEach((p: Player, i: number) => {
        p.name = playerName(state.games, i);
      });

      commit("addGame", game);
      return game;
    },
    addGame({ commit }, game: Game) {
      commit("addGame", game);
    },
    updateGameName({ commit }, { gameId, name }) {
      commit("updateGameName", { gameId, name });
    },
    removeGame({ commit }, id: string) {
      commit("removeGame", id);
    },
    archiveGame({ commit }, id: string) {
      commit("archiveGame", id);
    },
    unarchiveGame({ commit }, id: string) {
      commit("unarchiveGame", id);
    },
    addRound({ commit }, { gameId, playerId, score }) {
      commit("addRound", { gameId, playerId, score });
    },
    addPlayer({ commit }, gameId: string) {
      commit("addPlayer", gameId);
    },
    removePlayer({ commit }, { gameId, playerId }) {
      commit("removePlayer", { gameId, playerId });
    },
    updatePlayerName({ commit }, { gameId, playerId, name }) {
      commit("updatePlayerName", { gameId, playerId, name });
    },
  },
  plugins: [vuexLocal.plugin],
});

function playerName(games: Game[], index: number): string {
  let newest = 0;
  let name = `Player ${index + 1}`;
  games.forEach((game) => {
    if (game.players.length > index && game.date > newest) {
      name = game.players[index].name;
      newest = game.date;
    }
  });
  return name;
}

function withGame(
  state: GameState,
  gameId: string,
  callback: (gameIndex: number) => void
) {
  state.games.some((game: Game, gameIndex: number) => {
    if (game.id !== gameId) {
      return false;
    }
    callback(gameIndex);
    return true;
  });
}

function withPlayer(
  state: GameState,
  gameId: string,
  playerId: string,
  callback: (gameIndex: number, playerIndex: number) => void
) {
  withGame(state, gameId, (gameIndex) => {
    state.games[gameIndex].players.some(
      (player: Player, playerIndex: number) => {
        if (player.id !== playerId) {
          return false;
        }
        callback(gameIndex, playerIndex);
        return true;
      }
    );
  });
}
