
import Logo from '@/components/Logo.vue';
import InstallationPrompt from '@/components/InstallationPrompt.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Breadcrumb,
    Logo,
    InstallationPrompt,
  }
})
