import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "mx-auto max-w-lg h-full" }
const _hoisted_3 = { class: "p-4" }
const _hoisted_4 = {
  key: 0,
  class: "cursor-pointer mt-10 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumb = _resolveComponent("breadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_logo = _resolveComponent("logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_installation_prompt = _resolveComponent("installation-prompt")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_breadcrumb),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("main", _hoisted_3, [
        _createVNode(_component_router_view)
      ]),
      _createVNode(_component_router_link, { to: "/about" }, {
        default: _withCtx(() => [
          (_ctx.$route.name !== 'about')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_logo, { class: "w-3/4 mx-auto" })
              ]))
            : _createCommentVNode("", true)
        ], undefined, true),
        _: 1
      }),
      _createVNode(_component_installation_prompt)
    ])
  ]))
}