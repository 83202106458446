import {
  RouteLocationNormalized,
  createRouter,
  createWebHistory,
} from "vue-router";
import Home from "./views/Home.vue";
import GamePlay from "./views/GamePlay.vue";
import History from "./views/History.vue";
import Player from "./views/Player.vue";
import { computed, ref } from "vue";

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/game/:gameId",
      name: "game",
      component: GamePlay,
      props: true,
    },
    {
      path: "/game/:gameId/player/:playerId",
      name: "player",
      component: Player,
      props: true,
    },
    {
      path: "/game/:gameId/history",
      name: "history",
      component: History,
      props: true,
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue"),
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () =>
        import(/* webpackChunkName: "privacy" */ "./views/Privacy.vue"),
    },
    {
      path: "/installed",
      name: "installed",
      component: () =>
        import(/* webpackChunkName: "privacy" */ "./views/Installed.vue"),
    },
  ],
});

export function useGameId(route: RouteLocationNormalized) {
  return computed(() => route.params.gameId as string);
}

export function usePlayerId(route: RouteLocationNormalized) {
  return computed(() => route.params.playerId as string);
}
