export {
  key,
  number,
  character,
};

function number(min: number = 0, max: number = 1) {
  return Math.floor(Math.random() * (max - min)) + min;
}

function character(): string {
  const set = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  return set[number(0, set.length)];
}

function key(length: number = 12): string {
  const out = [];
  while (out.length < length) {
    out.push(character());
  }
  return out.join('');
}
