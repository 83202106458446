import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en);
const timeago = new TimeAgo('en-US');

export const ago = (value: number | null): string => {
  if (value === null || value === 0) {
    return '';
  }
  return timeago.format(value);
};
