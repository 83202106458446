
import { Game, lastPlayed } from '@/store';
import GameCard from '@/components/GameCard.vue';
import { AddIcon, ExpandMoreIcon, ExpandLessIcon } from '@/icons';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
  props: ['gameId'],

  components: {
    GameCard,
    AddIcon,
    ExpandMoreIcon,
    ExpandLessIcon,
  },

  data() {
    return {
      showArchive: false,
    }
  },

  computed: {
    games(): Game[] {
      return this.$store?.state?.games ?? [];
    },

    game(): Game | null {
      return this.games.find((g) => g.id === this.gameId) ?? null;
    },

    archivedGames(): Game[] {
      return this.sorted.filter((g) => g.archived);
    },

    activeGames(): Game[] {
      return this.sorted.filter((g) => !g.archived);
    },

    sorted(): Game[] {
      const games = this.games ? [...this.games] : [];
      games.sort((a, b) => lastPlayed(b) - lastPlayed(a));
      return games;
    },
  },

  methods: {
    ...mapActions([
      'archiveGame',
      'createGame',
      'removeGame',
      'selectGame',
      'unarchiveGame',
    ]),

    selectGame(game: Game) {
      this.$router.push({ name: 'game', params: { gameId: game.id } });
    },

    async startGame() {
      const game = await this.createGame();
      this.selectGame(game);
    },
  }
});
