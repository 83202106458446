import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "game" }
const _hoisted_2 = {
  key: 0,
  class: "flex"
}
const _hoisted_3 = { class: "text-gray-300 flex-grow" }
const _hoisted_4 = { class: "flex-shrink text-right" }
const _hoisted_5 = {
  key: 1,
  class: "players"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editable = _resolveComponent("Editable")!
  const _component_history_icon = _resolveComponent("history-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PlayerCard = _resolveComponent("PlayerCard")!
  const _component_add_person_icon = _resolveComponent("add-person-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.game)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, [
            _createVNode(_component_Editable, {
              text: _ctx.game.name,
              onChange: _cache[0] || (_cache[0] = (name) => _ctx.updateGameName({ gameId: _ctx.gameId, name }))
            }, null, 8, ["text"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              class: "text-white",
              to: { name: 'history', params: { gameId: _ctx.gameId } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_history_icon)
              ], undefined, true),
              _: 1
            }, 8, ["to"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.game && _ctx.game.players)
      ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.game.players, (player) => {
            return (_openBlock(), _createBlock(_component_PlayerCard, {
              key: player.id,
              player: player,
              onAdd: ({ player, score }) => _ctx.addRound({ gameId: _ctx.game?.id, playerId: player.id, score }),
              onSelect: (player) => _ctx.selectPlayer(player),
              onRemove: (player) => _ctx.removePlayer({ gameId: _ctx.game?.id, playerId: player.id })
            }, null, 8, ["player", "onAdd", "onSelect", "onRemove"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      class: "btn primary text-center w-full",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.newPlayer && _ctx.newPlayer(...args)))
    }, [
      _createTextVNode("New Player "),
      _createVNode(_component_add_person_icon)
    ])
  ]))
}