
import { AddPersonIcon, HistoryIcon } from '@/icons';
import { Game, Player } from '@/store';
import PlayerCard from '@/components/PlayerCard.vue';
import Editable from '@/components/Editable.vue';
import { mapActions } from 'vuex';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    gameId: {
      type: String,
      required: true,
    },
  },
  
  methods: {
    ...mapActions([
      'addPlayer',
      'updateGameName',
      'removePlayer',
      'addRound'
    ]),

    newPlayer() {
      if (this.game) {
        this.addPlayer(this.game.id);
      }
    },

    selectPlayer(player: Player) {
      this.$router.push({
        name: 'player',
        params: { gameId: this.gameId, playerId: player.id },
      });
    }
  },

  components: {
    PlayerCard,
    AddPersonIcon,
    HistoryIcon,
    Editable,
  },

 mounted() {
    if (!this.game) {
      this.$router.push({ name: 'home' });
    }
  },

  computed: {
    games(): Game[] {
      return this.$store?.state?.games ?? [];
    },

    game(): Game | null {
      if (!this.games) {
        return null;
      }
      return this.games.find((game) => game.id === this.gameId) || null;
    },

    hasHistory(): boolean {
      return !!(this.game && this.game.players.some((p) => p.rounds && p.rounds.length > 0));
    },
  }
})
