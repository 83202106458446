import { key } from '@/util/random';
import { Player, newPlayer } from './Player';

export {
  Game,
  newGame,
  lastPlayed,
};

interface Game {
  id: string;
  name: string;
  date: number;
  archived: boolean;
  players: Player[];
}

function newGame(playerCount = 2, overrides?: {}): Game {
  const players: Player[] = [];
  for (let i = 0; i < playerCount; i++) {
    players.push(newPlayer());
  }

  return {
    id: key(),
    name: '',
    date: Date.now(),
    archived: false,
    players,
    ...overrides,
  };
}

function lastPlayed(game: Game): number {
  let max = game.date;
  game.players.forEach((player: Player) => {
    if (!player.rounds) {
      return;
    }
    player.rounds.forEach((round) => {
      max = Math.max(round.time, max);
    });
  });
  return max;
}
