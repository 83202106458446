
import { Game, Player } from '@/store';
import { defineComponent } from 'vue';
import { useGameId, usePlayerId } from '@/router';
import { useRoute } from 'vue-router';

export default defineComponent({
  setup() {
    const route = useRoute();
    const gameId = useGameId(route);
    const playerId = usePlayerId(route);

    return {
      gameId,
      playerId,
    };
  },

  computed: {
    games(): Game[] | [] {
      return this.$store?.state?.games ?? [];
    },

    game(): Game | undefined {
      return this.games && this.games.find((game) => game.id === this.gameId);
    },

    player(): Player | undefined {
      return this.game && this.game.players.find((player) => player.id === this.playerId);
    },
}
})
