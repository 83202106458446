import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "game"
}
const _hoisted_2 = { class: "text-gray-300" }
const _hoisted_3 = { class: "calculator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editable = _resolveComponent("editable")!
  const _component_calculator = _resolveComponent("calculator")!

  return (_ctx.player)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode(_component_editable, {
            text: _ctx.player.name,
            onChange: _cache[0] || (_cache[0] = (name) => _ctx.updatePlayerName({ gameId: _ctx.gameId, playerId: _ctx.playerId, name }))
          }, null, 8, ["text"])
        ]),
        _createElementVNode("section", _hoisted_3, [
          _createVNode(_component_calculator, {
            score: _ctx.player.score,
            onAdd: _ctx.add
          }, null, 8, ["score", "onAdd"])
        ])
      ]))
    : _createCommentVNode("", true)
}